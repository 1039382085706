.edge{
    position: absolute;
    top: -50px;

    font-size: 16px;
}

.leftEdge{
    left: -10px;
}

.rightEdge{
    right: -20px;
}

.sliderStyle{
    position: relative;
    width: 80%;
    margin-top: 30px;
}
