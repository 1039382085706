$bluecolor: #018ffd;
$nav-pills-link-active-bg: $bluecolor;
$font-family-sans-serif: "Lato", sans-serif !default;
$body-bg: #edf2f6;
$primary: $bluecolor;
$link-color: #0d111d;
$link-hover-color: $bluecolor;
$border-color: #dde2e6;
$table-border-color: #dde2e6;
$table-striped-color: #dde2e6;
$table-group-separator-color: #dde2e6;
$nav-tabs-link-active-color: $bluecolor !default;
$header-bg-color: #e56d64;
$secondary: #e56d64;

$position-values: (
  0: 0,
  50: 50%,
  10: 10px,
  100: 100%,
);

@import "~bootstrap/scss/bootstrap";

a:hover {
  color: $bluecolor;
}
.nav-link {
  &.active,
  &.hover,
  &.focus {
    color: $bluecolor;
    border-bottom: 2px solid;
  }
}

.btn-primary {
  color: #fff;
}

.nav-pills {
  .nav-link {
    background: #f0f2f5;
  }
}

.bg-header {
  background: $header-bg-color;
}

.bg-light-green {
  background: #6cd300;
}
.ml-10 {
  margin-left: 10px;
}
