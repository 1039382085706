.allRecording {
  width: 100%;
  float: left;
}

.playerWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 350px;
  margin-top: -150px;
  margin-left: -300px;
  background: black;
}

.closeIcon {
  position: absolute;
  right: 0px;
  margin-right: 5px;
  margin-top: 5px;
}

.playerWrapperVideo {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .playerWrapper {
    width: 400px;
    height: 300px;
    margin-top: -150px;
    margin-left: -200px;
  }
}

@media only screen and (max-width: 320px) {
  .playerWrapper {
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
  }
}
