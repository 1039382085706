.keyStatistics {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
}
.tableKeyStats thead {
    border: transparent;
}
.tableKeyStats thead th,
.tableKeyStats td {
    padding: 0em;
    width: 65px;
    border-right: 1px solid #DDE2E6;
    border-left: 1px solid #DDE2E6;
}

.tableKeyStats td,
.tableKeyStats th {
    padding-top: 0 ;
    text-align: center;
}

.tableKeyStats thead th:first-child,
.tableKeyStats td:first-child {
    text-align: center ;
    width: 115px;
}

.tableKeyStats {
    float: left;
    border: none;
    box-shadow: 0 0 1px #ccc;
    margin-bottom: 0;
}

.borderUpper {
    width: 100%;
    float: left;
    border: 1px solid #e7e8e8;
    border-radius: 5px;
    overflow: hidden;
}

.tableKeyStats tbody tr,
.tableKeyStats tbody td,
.tableKeyStats tbody th {
    border-bottom: none ;
    border-top: none ;
    border-left: none;
}

.tableKeyStats thead tr {
    background: #E7EDF3;
}

.tableKeyStats thead th {
    padding-top: 15px ;
    padding-bottom: 17px ;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    color: #363547;
    border-top: none;
    border-color: #DDE2E6;
}

.tableKeyStats .borderUpper td:last-child,
.tableKeyStats .borderUpper th:last-child {
    border-right: none;
}

.tableKeyStats  thead th:first-child {
    border-left: none;
    border-bottom: none;
}

.tableKeyStats  thead th:first-child {
    text-align: left ;
    width: 20px ;
    max-width: 20px ;
    min-width: 20px ;
    padding-left: 17px ;
    border-bottom: none;
}

.tableKeyStats  tbody th {
    width: 20px;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3F4255;
    padding-top: 8px ;
    padding-bottom: 10px ;
    border-color: #DDE2E6;
}

.tableKeyStats  tbody tr:first-child th {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #1F3780;
    padding-bottom: 6px ;
    padding-top: 10px ;
}

.tableKeyStats  {
    border: none ;
    box-shadow: 0 0 1px #ccc;
    margin-bottom: 0;
}


.tableKeyStats tbody + tbody {
    border: none;
}

.tableKeyStats  tbody:nth-child(2) {
    background: #F5F7FA;
}

.tableKeyStats  tbody:nth-child(3) {
    background: #FAFBFC;
}

.tableKeyStats  tbody:nth-child(4) {
    background: #F5F7FA;
}

@media only screen and (max-width:767px) {
    .keyStatistics {
        padding: 15px;
    }
}