.currentDay {
  color: black !important;
  background-color: #ffffff !important;
}

.calender-outer {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dde2e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgb(173 187 216 / 25%);
  border-radius: 10px;
  padding: 20px;
}

.state_data_section .active-days {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.state_data_section .active-days h4 {
  font-size: 37px;
  font-weight: 500;
  margin-bottom: 0px;
}

.state_data_section .active-days span {
  font-size: 14px;
  line-height: 17px;
  color: #6c6c6c;
  font-weight: 300;
}

.state_data_section .active-days i {
  font-size: 20px;
  border: 1px solid #ababab;
  border-radius: 51%;
  padding: 7px 7px;
}

.calender-warpper .modeWrapper .modeButton {
  border: 1px solid #ddd;
  margin: 0 4px;
}
.calender-warpper .modeWrapper .modeButton.modeButtonActive {
  border: 1px solid #ff7552 !important;
  margin: 0 4px;
  background: #ffc6b870;
}
.calender-warpper .calendarHeader h1 {
  font-size: 34px;
  text-align: center;
  margin: 15px 0;
}
.calender-warpper .calendarHeaderButtons button {
  border: 1px solid;
  padding: 7px 11px;
}
.calender-warpper .calendarDayOfWeek div {
  text-align: center;
  padding: 11px 0;
  border-bottom: 1px solid #ddd;
}
.calender-warpper  .weekRow .dayCell .dayText span {
  position: relative;
  top: 27px;
  left: -155%;
  background: #e9e9e9;
  padding: 5px 9px;
  border-radius: 21px;
  z-index: 2;
}
.calender-warpper .dayCellEventWrapper .dayCellEvent.eventMiddle,
.calender-warpper .dayCellEventWrapper .dayCellEvent.eventStart,
.calender-warpper .dayCellEventWrapper .dayCellEvent.eventEnd {
  position: absolute;
  top: 5px;
  z-index: 1;
}
.calender-warpper .dayCellEvent.eventInactive{
  opacity:0 !important;
}
.calender-warpper .dayCellEvent.eventMiddle.eventInactive,
.calender-warpper .dayCellEvent.eventStart.eventInactive,
.calender-warpper .dayCellEvent.eventEnd.eventInactive{
  opacity:1 !important;
}
.calender-warpper .dayCellEventWrapper{
  position:relative;
}
/* .calender-warpper  .weekRow .dayCell {
  height: 80px;
} */
/* .calender-warpper  .weekRow .dayCell .dayText {
  position: abl;
  left: -24px;
  top: 0px;
  background: #e5e5e5ab;
  height: 27px;
  width: 28px;
  border-radius: 34px;
}  */
.active_sessions_of_weeks{
  position: relative;
  top: 247px;
  margin-left: 27px;
}
.active_sessions_of_weeks .circle{
  width: 30px;
  height: 30px;
  background: #ffafa9;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}
.active_sessions_of_weeks .circle i{
  color: #fff;
  padding: 3px 4px;
  font-size: 21px;
}
.active_sessions_of_weeks li{
  list-style: none;
  margin-bottom: 67px;
}
.active_sessions_of_weeks .circle.active{
  background: #e56d64;
}
.active_sessions_of_weeks li .line {
  height: 78px;
  width: 3px;
  background: #fd9a93;
  position: absolute;
  left: 15px;
  z-index: 1;
}

.row-padding {
  padding: 32px 28px;
}

.dayCellEvent span.eventTitle {
  
}
.calender-warpper .selected-date {
  width: 38px;
  height: 36px;
  background: #e56d64;
  border-radius: 50%;
  font-size: 15px;
  position: absolute;
  z-index: 2;
  top: 62%;
  left: 64%;
  transform: translate(-50%, -43%);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width:1440px){
  .calender-warpper .selected-date {
  }
}

/* full calender css styles  */
/* .full-calender-selected-date.fc-h-event{
  position: relative;
  top: -44px;
  width: 36px;
  height: 36px;
  left: 25px;
  background: #e56d64;
  border-radius: 50%;
  padding: 5px 6px 5px 8px;
  font-size: 15px;
  border: 1px solid #e56d64 !important;
  z-index: 9 !important;
} 
.fc-daygrid-day {
  height: 115px;
}
.fc-daygrid-day-number,.fc-daygrid-day-number:hover {
  top: 39px;
  left: -21px;
  z-index: 7 !important;
  background: #ddd;
  padding: 6px 0 !important;
  text-align: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-decoration: none;
}
.long-event-selected-date.fc-h-event {
  border: 1px solid #6cd300 !important;
  background-color: #6cd300 !important;
  height: 44px;
  top: -3px;
  border-radius: 50px;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.fc-col-header-cell {
  height: 61px;
  padding: 12px 0 !important;
}
.fc-col-header-cell a{
  text-decoration: none;
}
.fc-scroller {
  overflow: visible !important;
}
.fc .fc-scrollgrid-liquid {
  height: 650px !important;
}
.fc-theme-standard td, .fc-theme-standard th{
  border-left: none;
  border-right: none;
}

.fc-today-button {
  background-color: #e56d64 !important;
  border-color: #e56d64 !important;
}
.fc-prev-button.fc-button  {
  background-color: #6cd300 !important;
}
.fc-next-button.fc-button  {

}
.fc-col-header {
  width: 100% !important;
}
.fc-daygrid-body,.fc-scrollgrid-sync-table {
  width: 100% !important;
} */

@media only screen and (max-width:600px){
  .calender-warpper .weekRow .dayCell .dayText span{
    left: -67% !important;
    padding: 5px 7px !important;
    font-size: 12px;
  }
  .active_sessions_of_weeks{
    margin-left: 11px;
  }
  .calender-warpper .selected-date{
    width: 27px !important;
    height: 28px !important;
    left: 55% !important;
    font-size: 12px !important;
  }
  .calender-outer{
    padding:7px !important;
  }
  .state_data_section{
    margin-top: 20px;
  }
}