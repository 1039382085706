.bgColor {
  background: #ffffff;
  border: 1px solid #dde2e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(173, 187, 216, 0.25);
  border-radius: 10px;
  float: left;
  width: 100%;
  padding: 21px 0;
}

.navTabs a {
  padding: 0;
  font-size: 20px;
  line-height: 24px;
  display: inline-block;
  font-weight: 700;
}

.navTabs .nav-item.show .nav-link,
.navTabs .nav-link.active {
  border: none;
  background: transparent;
}

.navTabs {
  width: 100%;
  float: left;
  text-align: center !important;
  justify-content: center;
  border-bottom: 1px solid #dde2e6;
  min-height: 46px;
}

.navTabs a {
  /*margin-right:50px; margin-left: 50px; */
  padding-bottom: 20px;
  &.active {
    border-bottom: 2px solid;
  }
}

.navTabs.nav-item {
  padding: 0;
}

.navTabs .nav-link:focus,
.navTabs .nav-link:hover {
  border: none;
}

.navLinks:focus,
.navLinks:hover,
.navTabs a.active.show {
  border-bottom: 2px solid;
}

.navTabs .nav-link {
  border: none;
}

@media only screen and (max-width: 767px) {
  .navTabs a {
    padding: 10px 0;
  }
}
