.sessionBox {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.modalHeader {
  button {
    border: none;
    background: none;
  }
}
