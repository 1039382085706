.topBox{
    width: 100%;
    float: left;
}

.rightBox {

    border-left: 1px solid #DDE2E6;

}

.scroolBox {
    float: left;
    width: 100%;
    overflow-y: scroll;
}

.chooseBox {
    border-bottom: 1px solid #dde2e6;
    padding-top: 15px;
    padding-bottom: 15px;
  }

.chooseBox p {
    margin: 0;
    float: left;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    padding: 0 10px;
    margin-bottom: 7px;
}

.chooseBox span {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: #363547;
    width: 100%;
    padding: 0 10px;
    line-height: 20px;
    display: inline-block;
    margin-top: 7px;
}


.checkBox ul {
    padding: 0;
    margin: 0;
    padding-top: 15px;
    padding-left: 10px;
}

.checkBox ul li {
    list-style: none;
    float: left;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #363547;
    margin-top: 20px;
    padding-left: 25px;
}

.checkBox ul h4 {
    margin: 0;
    float: left;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2B2E33;
}

.checkBox ul li input[type="checkbox"] {
    background: #FFFFFF;
    border: 1px solid #363547;
    box-sizing: border-box;
    border-radius: 4px;
    width: 18px;
    float: left;
    height: 18px;
    margin-left: -25px;
    -webkit-appearance: none;
    appearance: none;
}

input[type="checkbox"] {
    -webkit-appearance: initial;
    appearance: initial;
    background: gray;
    width: 40px;
    height: 40px;
    border: none;
    position: relative;
}

.checkBox ul li input[type="checkbox"]:checked:after {
    content: "X";
    color: #45474D;
    position: absolute;
    font-size: 16px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.checkBox {
    float: left;
    margin-bottom: 10px;
}

.dataFooter {
    width: 100%;
    float: left;
    border-top: 1px solid #DDE2E6;
    padding: 28px 30px 0;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style-type: none;
}

.dataFooter li {
    width: 100%;
    float: left;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #676A73;
    margin-bottom: 7px;
}

.dataFooter li span {
    float: left;
    width: 16px;
}

.dataFooter li p {
    float: left;
    width: fit-content;
    margin: 0;
}

.dataFooter h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    color: #0D111D;
    margin: 0;
    margin-bottom: 12px;
}

@media only screen and (max-width:767px) {
    .scrool-box {
        height: auto;
        overflow-x: visible;
    }
}