$tab-font-color: #363547;
.tableResponsive {
  table {
    th {
      border: 1px solid #dee2e6;
      vertical-align: baseline;
      padding-top: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      background: #f5f7fa;
      color: #3f4255;
    }

    td {
      padding: 0em;
      width: 65px;
      border-right: 1px solid #dde2e6;
      border-left: 1px solid #dde2e6;
      border-bottom: 1px solid #dde2e6;
    }
    .bgALSFRSR4 {
      background: #00ff04;
    }

    .bgALSFRSR0 {
      background: red;
    }

    .bgALSFRSR2 {
      background: yellow;
    }

    .bgALSFRSR3 {
      background: greenyellow;
    }

    .bgALSFRSR1 {
      background: orange;
    }

    .bgROADS2 {
      background: #00ff04;
    }

    .bgROADS0 {
      background: red;
    }

    .bgROADS1 {
      background: yellow;
    }
  }
}

.tableResponsive thead tr:first-child th:first-child {
  background: transparent;
  border: none !important;
}

.tableResponsive thead tr:first-child th:nth-child(2) {
  border-radius: 5px 0px 0 0 !important;
}

.tableResponsive thead tr:first-child th:last-child {
  border-radius: 0px 5px 0 0 !important;
  border-right: 1px solid #dde2e6 !important;
  box-shadow: 1px 2px 0px #cccccca1;
}
.tableResponsive thead tr:nth-child(2) th:first-child {
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #676a73;
  text-align: left !important;
  margin-top: -35px !important;
  display: block;
  margin-bottom: 16px;
  background: transparent;
}
.tableResponsive table th:first-child {
  background: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3f4255;
}
.tableResponsive table td {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  vertical-align: middle;
  color: #3f4255;
}
.tableResponsive thead tr:nth-child(2) th:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: $tab-font-color;
  background: #e7edf3;
  vertical-align: -webkit-baseline-middle;
  padding-top: 0 !important;
  border-top: none;
  padding-bottom: 15px !important;
}

.groupHeader thead tr:first-child th {
  border: none;
  background: #e7edf3;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: $tab-font-color;
  padding: 16px 0;
  border-right: 1px solid #dde2e6;
  width: 115px;
}

.navTabs {
  width: 100%;
  float: left;
  margin-bottom: 30px;
  margin-top: 40px;
  p {
    margin: 0;
    float: left;
    margin-right: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $tab-font-color;
  }

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $tab-font-color;
    padding: 10px 25px;
    background: #f0f2f5;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
  }
  .navTabsDisabled {
    background: #fff;
    color: $tab-font-color;
  }
}

.tabForTable ul li a:hover,
.tabForTable ul li a:focus,
a.clickOne.active {
  color: #fff;
}

.scroolDwoml {
  overflow-y: auto;
  height: 470px;
  width: 100%;
  direction: rtl;
  padding-right: 1px;

  table {
    direction: ltr;
  }
}

.glilopin {
  float: left;
  width: 100%;
  margin-top: 30px;
}
