.chooseBox {
  border-bottom: 1px solid #dde2e6;
  padding-top: 15px;
  padding-bottom: 15px;
}

.chooseBox p {
  margin: 0;
  float: left;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  padding: 0 10px;
  margin-bottom: 7px;
}

.chooseBox span {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: #363547;
  width: 100%;
  padding: 0 10px;
  line-height: 20px;
  display: inline-block;
  margin-top: 7px;
}
